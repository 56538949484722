<template>

    <v-form
            ref="form">
        <v-row>
            <v-col cols="12" class="pb-0">
                <h3>Wie können wir Sie erreichen?</h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
                <v-text-field
                        v-model="value.name"
                        label="Name"
                        :rules="nameRules"
                        required
                        solo
                        dense
                        suffix="*"
                        ref="nameField"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
                <v-text-field
                        v-show="withPhoneNumber"
                        v-model="value.phoneNumber"
                        label="Telefonnummer"
                        :rules="phoneNumberRules"
                        required
                        solo
                        dense
                        suffix="*"
                        ref="phoneNumberField"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
                <v-text-field
                        v-model="value.companyName"
                        label="Firmenname"
                        :rules="companyNameRules"
                        required
                        solo
                        dense
                        ref="companyNameField"
                ></v-text-field>
            </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field
                v-show="withEmail"
                v-model="value.email"
                label="E-Mail"
                :rules="emailRules"
                required
                :disabled="!!email"
                solo
                dense
                suffix="*"
                ref="emailField"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pb-0">
                <h3>Wohin sollen wir ihre Bestellung liefern?</h3>
            </v-col>

            <v-col cols="12" md="6" class="py-0">
                <v-text-field
                        v-model="value.address"
                        :rules="addressRules"
                        label="Adresse"
                        required
                        solo
                        dense
                        suffix="*"
                        ref="adressField"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
                <v-text-field
                        maxlength="250"
                        v-model="value.zipCode"
                        :rules="zipCodeRules"
                        label="Postleitzahl"
                        required
                        solo
                        dense
                        suffix="*"
                        ref="zipCodeField"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
                <v-text-field
                        v-model="value.city"
                        :rules="cityRules"
                        label="Stadt"
                        required
                        solo
                        dense
                        suffix="*"
                        ref="cityField"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
                <v-text-field
                        v-model="value.floor"
                        :rules="floorRules"
                        label="Etage"
                        solo
                        dense
                        ref="floorField"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import validateAddressMixin from "../mixins/validateAddressMixin";

    export default {
        name: "CompactAddressInput",
        mixins: [validateAddressMixin]
    }
</script>

<style lang="sass">
    .v-messages__message
        color: #ff4a4a

</style>