<template>
  <div>
    <div v-if="wizardStep==1">
      <fixed-sidebar-layout :loading="loading">
        <div slot="content">
          <v-container>
            <div v-if="addresses.length>0">
              <v-select
                  :items="addressesWithNew"
                  label="Adressen"
                  :item-text="addressName"
                  return-object
                  outlined
                  v-model="selectedAddress"
              ></v-select>
            </div>
            <checkout-address-input :zipCodes="zipCodes" :address="selectedAddress" :validate="validateForm"
                                    :phone-number="phoneNumber" :email="email" v-on:validityChanged="handleValidity"
                                    v-model="formInputAddress" with-phone-number with-email></checkout-address-input>
            <v-row>
              <v-col cols="12" class="pb-0">
                <h3>Haben Sie Anmerkungen an das Restaurant?</h3>
                <v-textarea class="pt-1"
                            label="Anmerkung an das Restaurant"
                            maxlength="250"
                            no-resize
                            outlined
                            solo
                            rows="3"
                            v-model="commentInput"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <h3>Haben Sie einen Gutschein?</h3>
                <discount-code-checker :disabled="discountCodeInputDisabled"
                                       :show-remove="discountCodeInputDisabled"
                                       @success="handleDiscountCodeSuccess"
                                       @remove="handleDiscountCodeRemove"
                                       @loading="handleDiscountCodeLoading"
                                       v-model="discountCodeInput"
                                       ref="codeChecker"
                ></discount-code-checker>
              </v-col>
              <v-col cols="10" class="mt-0 pt-0" v-if="discountCode">
                <v-lazy>
                  <v-banner single-line
                            color="light-green lighten-4"
                            width="100%">
                    <v-icon
                        slot="icon"
                        color="green"
                        size="36"
                    >
                      {{ mdiCheck }}
                    </v-icon>
                    <div v-if="discountCode.type=='cash'"><b>{{ discountCode.cashDiscount / 100 |currency }} Rabatt</b></div>
                    <div v-if="discountCode.type =='percent'"><b>{{ discountCode.percentOff }} % Rabatt</b></div>
                    <div class="pt-1" v-if="discountCode.minimumOrder > 0 && discountCode.minimumOrder > minimumOrder">Mindestbestellwert:
                      {{ discountCode.minimumOrder / 100 |currency }}
                    </div>
                    <template v-slot:actions>
                    </template>
                  </v-banner>
                </v-lazy>
              </v-col>
              <v-col cols="12" style="position: relative; z-index: 1;">
                <v-checkbox
                    class="{'pl-1': $vuetify.breakpoint.smAndDown}"
                    v-model="policyCheckbox"
                >
                  <div slot="label" :class="{'pl-3': $vuetify.breakpoint.smAndDown}">Ich akzeptiere die
                    <router-link :to="{ name: 'tac'}">AGB</router-link>
                    und
                    <router-link :to="{ name: 'privacy'}">Datenschutzerklärung</router-link>
                    .
                  </div>
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="6" style="position: relative; z-index: 1;">
                <v-btn @click="confirmAddress" class="mt-3" block large :x-large="$vuetify.breakpoint.mdAndUp" depressed color="light-green lighten-2"
                       :disabled="!policyCheckbox || numberOfItemsInCart <= 0 || totalPriceWithoutDeliveryFee  < minimumOrder || (discountCode && (discountCode.minimumOrder > 0) && (discountCode.minimumOrder > totalPriceWithoutDeliveryFee)) || discountCodeLoading">
                  <v-icon color="green darken-4" size="28px" class="pr-2">
                  {{ mdiArrowRight }}
                  </v-icon> Weiter
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <shopping-cart slot="sidebar"></shopping-cart>
      </fixed-sidebar-layout>
      <v-dialog
          v-if="!$vuetify.breakpoint.mdAndUp && acceptingOrders"
          fullscreen
          v-model="cartFloat"
      >
        <template v-slot:activator="{ on }">
          <v-slide-y-reverse-transition>
            <v-btn v-if="numberOfItemsInCart > 0" class="floatingButton" style="z-index: 10;" fixed bottom
                   right x-large
                   v-on="on">
              <v-badge overlap bottom left :content="numberOfItemsInCart">
                <v-icon>{{ mdiShopping }}</v-icon>
              </v-badge>
              {{ (totalPrice / 100)|currency }}
            </v-btn>
          </v-slide-y-reverse-transition>
        </template>
        <v-card>
          <v-card-title class="pb-0">
            Warenkorb
            <v-spacer></v-spacer>
            <v-btn icon @click="cartFloat = false">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <shopping-cart class="transition-fast-in-fast-out"></shopping-cart>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog
          v-if="zipDialog"
          v-model="zipDialog"
          :persistent="(!this.user.zipCode && this.getFullZipCode(this.formInputAddress.zipCode).deliveryFee > 0) || (this.user.zipCode && this.formInputAddress.zipCode !== this.user.zipCode.zipCode && this.getFullZipCode(this.formInputAddress.zipCode).deliveryFee !== this.user.zipCode.deliveryFee)"
          max-width="600">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-subtitle class="pb-1">
          </v-card-subtitle>
          <v-card-text class="pt-2">
            <span
                v-if="(!this.user.zipCode && this.getFullZipCode(this.formInputAddress.zipCode).deliveryFee > 0) || (this.user.zipCode && this.formInputAddress.zipCode !== this.user.zipCode.zipCode && this.getFullZipCode(this.formInputAddress.zipCode).deliveryFee !== this.user.zipCode.deliveryFee)">
            {{
                this.user.zipCode ?
                    'Die Adresse hat eine andere Liefergebühr, als die der vorher ausgewählten Postleitzahl.' :
                    'Die Adresse hat eine Liefergebühr.'
              }}
            Die neue Liefergebühr beträgt
            {{ getFullZipCode(formInputAddress.zipCode).deliveryFee / 100 | currency }} <br>
              </span>
            <span
                v-if="(this.getFullZipCode(this.formInputAddress.zipCode).minimumOrder > this.totalPriceWithoutDeliveryFee)">
              Der Mindestbestellwert von {{
                this.getFullZipCode(this.formInputAddress.zipCode).minimumOrder / 100 |currency
              }} der ausgewählten Adresse ist noch nicht erreicht!
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="zipDialog=false"
                   v-if="(!this.user.zipCode && this.getFullZipCode(this.formInputAddress.zipCode).deliveryFee > 0) || (this.user.zipCode && this.formInputAddress.zipCode !== this.user.zipCode.zipCode && this.getFullZipCode(this.formInputAddress.zipCode).deliveryFee !== this.user.zipCode.deliveryFee)">
              Abbrechen
            </v-btn>
            <v-btn text @click="handleZipErrorSubmit">{{
                (!this.user.zipCode && this.getFullZipCode(this.formInputAddress.zipCode).deliveryFee > 0) || (this.user.zipCode && this.formInputAddress.zipCode !== this.user.zipCode.zipCode && this.getFullZipCode(this.formInputAddress.zipCode).deliveryFee !== this.user.zipCode.deliveryFee) ? 'Akzeptieren' : 'Okay!'
              }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-container v-else>
      <v-row v-if="!loading">
        <v-col cols="12">
          <h1>Wählen Sie ihre Zahlungsmethode</h1>
        </v-col>
        <v-col cols="12" md="7" :order="$vuetify.breakpoint.smAndDown ? 'last' : ''">
          <v-lazy>
            <order-detail :order="{
                    items: [...this.items],
                    comment: this.commentInput,
                    address: this.formInputAddress,
                    deliveryFee: this.deliveryFee,
                    discountCode: this.discountCode
                }"
            unfinished-order></order-detail>
          </v-lazy>
          <v-btn color="light-green lighten-1" x-large block class="mt-10" @click="wizardStep = 1">
            Zurück
          </v-btn>
        </v-col>
        <v-col cols="12" md="5" style="position: relative; z-index: 1;">
          <div id="button-container">
            <v-btn class="mb-5" depressed  x-large @click="submitCashPayment">Bar bezahlen</v-btn>
            <div ref="paypalButton"></div>
          </div>
        </v-col>
        <v-dialog
            :fullscreen="$vuetify.breakpoint.xsOnly"
            v-model="phoneVerificationDialog"
            width="500"
            persistent
            eager
        >
          <v-card>
            <v-card-title
                class="headline grey lighten-2"
                primary-title
            >
              Telefonnummer verifizieren.
              <v-spacer></v-spacer>
              <v-btn @click="phoneVerificationDialog = false" icon>
                <v-icon>{{ mdiClose }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-lazy>
              <phone-wizard :initialPhoneNumber="formInputAddress.phoneNumber"
                            v-on:successful="handlePhoneVerificationSuccess"
                            :visible="phoneVerificationDialog">
                <div slot="initialText">
                  <p>Sie müssen ihre Telefonnummer bestätigen wenn Sie bar zahlen wollen.
                    Sie bekommen hierfür eine SMS mit einem Bestätigungscode gesendet.</p>
                </div>
              </phone-wizard>
            </v-lazy>
          </v-card>
        </v-dialog>

      </v-row>
      <v-layout align-center justify-center class="pt-12 pb-9" v-else>
        <v-progress-circular
            indeterminate
            size="85"
            color="primary"
        ></v-progress-circular>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

import PhoneWizard from "@/components/PhoneWizard";
import CheckoutAddressInput from "@/components/CheckoutAddressInput";
import ShoppingCart from "@/components/ShoppingCart";
import {mapGetters, mapActions} from "vuex";
import FixedSidebarLayout from "@/components/FixedSidebarLayout";
import firebase from 'firebase/app';
import {store} from "@/store";
import Vue from 'vue'
import OrderDetail from "@/components/OrderDetail";
import DiscountCodeChecker from "@/components/DiscountCodeChecker";
import {mdiCheck, mdiShopping, mdiClose, mdiArrowRight} from '@mdi/js'


export default {
  name: "checkout",
  components: {
    DiscountCodeChecker,
    CheckoutAddressInput,
    PhoneWizard,
    ShoppingCart,
    FixedSidebarLayout,
    OrderDetail
  },
  data: () => {
    return {
      mdiCheck,
      mdiShopping,
      mdiClose,
      mdiArrowRight,
      wizardStep: 1,
      cartFloat: false,
      loading: true,
      zipDialog: false,
      phoneVerificationDialog: false,
      selectedAddress: {
        address: '',
        floor: '',
        city: '',
        zipCode: '',
        name: '',
        companyName: '',
        new: true
      },
      formInputAddress: {
        address: '',
        floor: '',
        city: '',
        zipCode: '',
        name: '',
        companyName: '',
        phoneNumber: '',
      },
      validateForm: false,
      formValid: undefined,
      commentInput: "",
      discountCodeInputDisabled: false,
      discountCode: undefined,
      discountCodeLoading: false,
      discountCodeInput: '',
      policyCheckbox: false,
    }

  },
  computed: {
    ...mapGetters({
      addresses: 'userStore/addressesInRestaurantZipCodes',
      allAddresses:'userStore/addresses',
      user: 'userStore/user',
      selectedZipCode: 'userStore/zipCode',
      phoneNumber: 'userStore/phoneNumber',
      email: 'userStore/email',
      restaurantId: 'restaurantStore/id',
      getFullZipCode: 'restaurantStore/getFullZipCode',
      hasCheckedInfo: 'restaurantStore/hasCheckedInfo',
      acceptingOrders: 'restaurantStore/acceptingOrders',
      minimumOrder: 'userStore/minimumOrder',
      zipCodes: 'restaurantStore/zipCodesShort',
      hasCheckedCart: 'shoppingCartStore/hasCheckedCart',
      numberOfItemsInCart: 'shoppingCartStore/numberOfItemsInCart',
      items: 'shoppingCartStore/items',
      deliveryFee: 'shoppingCartStore/deliveryFee',
      totalPriceWithoutDeliveryFee: 'shoppingCartStore/totalPriceWithoutDeliveryFee',
      totalPrice: 'shoppingCartStore/totalPrice',
      menuLoaded: 'menuStore/menuLoaded',
    }),
    addressesWithNew() {
      let addresses = [...this.addresses]
      addresses.unshift({
        address: '',
        floor: '',
        city: '',
        zipCode: '',
        name: '',
        companyName: '',
        phoneNumber: '',
        new: true
      })
      return addresses
    },


  },
  methods: {
    ...mapActions({
      setZipCode: 'userStore/setZipCode',
    }),
    handleDiscountCodeLoading(val) {
      this.discountCodeLoading = val
    },
    handleDiscountCodeSuccess(obj) {
      this.discountCode = obj
      this.discountCodeInputDisabled = true
    },
    handleDiscountCodeRemove() {
      this.discountCode = undefined
      this.discountCodeInputDisabled = false
    },
    confirmAddress() {
      if (this.formValid) {
        if ((!this.user.zipCode && this.getFullZipCode(this.formInputAddress.zipCode).deliveryFee > 0) || (this.user.zipCode && this.formInputAddress.zipCode !== this.user.zipCode.zipCode && this.getFullZipCode(this.formInputAddress.zipCode).deliveryFee !== this.user.zipCode.deliveryFee) || this.getFullZipCode(this.formInputAddress.zipCode).minimumOrder > this.totalPriceWithoutDeliveryFee) {
          this.zipDialog = true
        } else {
          //this handles the case that a different zip code was chosen but the delivery fee is the same.
          if (!this.user.zipCode || this.formInputAddress.zipCode !== this.user.zipCode.zipCode) {
            this.setZipCode(this.getFullZipCode(this.formInputAddress.zipCode))
          }
          if (this.$refs.codeChecker.codeInput.length >= 5 && this.$refs.codeChecker.state == undefined && !this.discountCode) {
            this.$refs.codeChecker.handleCheckIconClick().then(res => {
              if (res) {
                this.wizardStep = 2
                Vue.nextTick().then(() => {
                  window.scrollTo(0, 0)
                  this.createPaypalButtons()
                })
              }
            })
          } else {
            this.wizardStep = 2
            Vue.nextTick().then(() => {
              window.scrollTo(0, 0)
              this.createPaypalButtons()
            })
          }
        }
      } else {
        this.validateForm = true
      }

    },
    handleZipErrorSubmit() {
      this.setZipCode(this.getFullZipCode(this.formInputAddress.zipCode)).then(() => {
        this.zipDialog = false
        if (!(this.selectedZipCode.minimumOrder > this.totalPriceWithoutDeliveryFee)) {
          this.confirmAddress()
        }
      })
    },
    handlePhoneVerificationSuccess(validatedPhoneNumber) {
      this.formInputAddress.phoneNumber = validatedPhoneNumber
      this.phoneVerificationDialog = false
      Vue.nextTick().then(() => {
        this.submitCashPayment()
      })
    },
    getTotalPriceOfItem(item) {
      let price = Number(item.price);
      item.extras.forEach(extra => {
        price += Number(extra.price);
      });
      Object.values(item.questions).forEach(question => {
        price += Number(question.answer.price);
      })
      price = price * item.count
      return price
    },
    createPaypalButtons() {
      //TODO: THIS COULD REALLY BE OPTIMIZED, AS A LOT OF GARBAGE IS LOADED BEFORE CREATEORDER IS CLICKED WHICH
      //TODO: MIGHT NOT BE NEEDED
      //TODO: ALSO price of item should be evaluated in cart prolly in getter
      //ESLINT needs to be disabled, otherwise it says paypal is undefined.
      /* eslint-disable */
      let order = {
        items: [...this.items],
        paymentOption: 'paypal',
        comment: this.commentInput,
        address: this.formInputAddress
      }
      let orderTotalPrice = this.totalPrice
      let discount
      let discountCode
      if (this.discountCode) {
        discountCode = this.discountCode.code
        order.discountCode = discountCode
        if (this.discountCode.type == 'cash') {
          discount = this.discountCode.cashDiscount
        } else if (this.discountCode.type == 'percent') {
          discount = Math.ceil(this.totalPriceWithoutDeliveryFee * (this.discountCode.percentOff / 100))
        }
      }
      let orderDeliveryFee = this.deliveryFee

      let itemList = order.items.map(item => {
        return {
          "name": item.name,
          "quantity": item.count,
          "unit_amount": {
            "value": ((this.getTotalPriceOfItem(item) / item.count) / 100).toFixed(2),
            "currency_code": "EUR"
          }
        }
      })
      let orderData = {
        "purchase_units": [{
          "description": "Essen bestellen",
          "amount": {
            "currency_code": 'EUR',
            "value": (orderTotalPrice / 100).toFixed(2),
            "breakdown": {
              "item_total": {
                "currency_code": "EUR",
                "value": ((orderTotalPrice - orderDeliveryFee) / 100).toFixed(2)
              },
              "shipping": {
                "currency_code": "EUR",
                "value": (orderDeliveryFee / 100).toFixed(2)
              }
            }
          },
          "items": itemList,
          "shipping": {
            "address": {
              "name": {
                "full_name": order.address.name
              },
              "address_line_1": order.address.address,
              "address_line_2": order.address.floor || '',
              "admin_area_2": order.address.city,
              "postal_code": order.address.zipCode,
              "country_code": "DE"
            }
          }
        }],
      }
      if (discount) {
        orderData.purchase_units[0].amount.value = ((orderTotalPrice - discount) / 100).toFixed(2)
        orderData.purchase_units[0].amount.breakdown.discount = {
          'currency_code': 'EUR',
          'value': (discount / 100).toFixed(2)
        }
      }

      paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create(orderData);
        },
        onApprove: (data) => {
          this.loading = true
          this.addAddressIfNeeded()
          const handleOrder = firebase.app().functions('europe-west3').httpsCallable('handleOrderSubmit')
          let arg = {
            order: order,
            restaurantId: this.restaurantId,
            paypalOrderId: data.orderID,
          }
          if (discountCode) {
            arg.discountCode = discountCode
          }
          return handleOrder(arg).then(obj => {
            if (obj.data) {
              this.$store.commit('shoppingCartStore/clearCart')
              obj.data.timestamp = new Date()
              this.$store.commit('orderStore/ADD_ORDER', obj.data)
              this.$router.push({name: 'order-ID', params: {id: obj.data.id}})
            }
          }).catch(err => {
            console.log(err)
            this.loading = false
            this.wizardStep = 1
          })
        },
        locale: 'de_DE',
        style: {
          label: 'pay',
        }
      }).render(this.$refs.paypalButton,);
      /* eslint-enable */
    },
    handleValidity(value) {
      this.formValid = value
    },
    addressName(address) {
      if (!address.new) {
        return address.address + ' - ' + address.city
      } else {
        return 'Neue Adresse eingeben'
      }
    },
    submitCashPayment() {
      if (!this.phoneNumber) {
        this.phoneVerificationDialog = true
      } else {
        this.loading = true
        let order = {
          items: [...this.items],
          paymentOption: 'cash',
          comment: this.commentInput,
          address: this.formInputAddress
        }
        console.log(order)
        if (this.discountCode) {
          order.discountCode = this.discountCode.code
        }
        const handleOrder = firebase.app().functions('europe-west3').httpsCallable('handleOrderSubmit')
        this.addAddressIfNeeded()
        handleOrder({
          order: order,
          restaurantId: this.restaurantId
        }).then(obj => {
          this.$store.commit('shoppingCartStore/clearCart')
          obj.data.timestamp = new Date()
          this.$store.commit('orderStore/ADD_ORDER', obj.data)
          this.$router.push({name: 'order-ID', params: {id: obj.data.id}})
        }).catch(err => {
          console.log(err)
          this.loading = false
          this.wizardStep = 1
        })
      }
    },
    addAddressIfNeeded() {
      if (this.user && this.user.data && this.user.data.uid && !this.allAddresses.some(x => x.address == this.formInputAddress.address &&
          x.city == this.formInputAddress.city && x.companyName == this.formInputAddress.companyName &&
          x.floor == this.formInputAddress.floor && x.name == this.formInputAddress.name &&
          x.zipCode == this.formInputAddress.zipCode)){
        let newAdd = JSON.parse(JSON.stringify(this.formInputAddress))
        delete newAdd.id
        delete newAdd.email
        delete newAdd.phoneNumber
        firebase.firestore().collection("users").doc(this.user.data.uid).collection("addresses").add(
            newAdd
        ).then(docRef => {
          newAdd.id = docRef.id;
          this.$store.commit('userStore/ADD_ADDRESS', newAdd);
        }).catch(function (error) {
          console.error("Error adding document: ", error);
        });
          }
    }
  },
  created() {
    this.$loadScript("https://www.paypal.com/sdk/js?client-id=Ae_x5E69e-sMph05atZl4-x0nhRHY4onUucbZk0CTEzxD_jVyZM1UsTdrx_2cCSkrzNGcoQv1ey2GIe2&currency=EUR&intent=capture")
    const initializeFunctions = firebase.app().functions('europe-west3').httpsCallable('initializeFunctions')
    initializeFunctions({
      restaurantId: this.restaurantId,
    }).then((response) => {
      console.log(response)
    })
    if (this.hasCheckedInfo) {
      if (!this.acceptingOrders) {
        this.$router.push({name: 'menu'})
      }
      if (!this.hasCheckedCart) {
        store.dispatch('shoppingCartStore/getCartFromLocalStorage');
      }
      if (this.selectedZipCode && this.selectedZipCode.zipCode) {
        this.selectedAddress.zipCode = this.selectedZipCode.zipCode
      }
      this.loading = false
    }
  },
  watch: {
    hasCheckedInfo(val) {
      if (val && !this.acceptingOrders) {
        this.$router.push({name: 'menu'})
      } else if (val) {
        if (!this.hasCheckedCart) {
          store.dispatch('shoppingCartStore/getCartFromLocalStorage');
        }
        if (this.selectedZipCode && this.selectedZipCode.zipCode) {
          this.selectedAddress.zipCode = this.selectedZipCode.zipCode
        }
        this.loading = false
      }
    },
    selectedAddress() {
      this.validateForm = false
    },
    selectedZipCode(zipCode) {
      if (this.selectedAddress) {
        this.selectedAddress.zipCode = zipCode
      }
    }
  },

}
</script>

<style scoped lang="sass">
#button-container
  display: flex
  flex-direction: column
  max-width: 750px
  vertical-align: after-edge

</style>
