<template>
  <div>
    <v-row no-gutters>
      <v-col cols="10">
        <v-text-field
            label="Rabattcode"
            v-model="codeInput"
            :disabled="loading || disabled"
            :loading="loading"
            :error="state == 'error'"
            :success="state == 'success'"
            :error-messages="state=='error' ? 'Der Gutschein konnte nicht gefunden werden.' : ''"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn
            icon
            @click="showRemove ? handleRemoveIconClick() : handleCheckIconClick()"
            :disabled="loading || (disabled & !showRemove) || (codeInput.length <= 4)"
        >
          <v-icon v-if="!showRemove">{{ mdiCheck }}</v-icon>
          <v-icon v-else>{{ mdiDelete }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import firebase from "firebase/app";
import {mapGetters} from "vuex";
import { mdiCheck, mdiDelete } from '@mdi/js'


export default {
  name: "DiscountCodeChecker",
  props: {
    value: String,
    disabled: Boolean,
    showRemove: Boolean,
  },
  data() {
    return {
      mdiCheck:mdiCheck,
      mdiDelete:mdiDelete,
      codeInput: '',
      loading: false,
      state: undefined
    }
  },
  computed:{
    ...mapGetters({
      restaurantId: 'restaurantStore/id',
    }),
  },
  methods: {
    handleCheckIconClick() {
        this.$emit('loading', true)
        this.loading = true
        const handleOrder = firebase.app().functions('europe-west3').httpsCallable('checkDiscountCode')
        return handleOrder({
          code: this.codeInput.toUpperCase(),
          restaurantId: this.restaurantId
        }).then(obj => {
          this.$emit('loading', false)
          this.loading = false
          if(obj.data == false){
            this.state = 'error'
            return false
          } else {
            this.state = 'success'
            this.$emit('success', obj.data)
            return true
          }
        }).catch(err => {
          this.$emit('loading', false)
          this.loading = false
          this.state = 'error'
          throw new Error(err)
        })
    },
    handleRemoveIconClick(){
        this.$emit('remove')
        this.codeInput = ''
    }
  },
  watch: {
    codeInput(newCode) {
      this.$emit('input', newCode)
      this.state = undefined
    },
    value(newValue){
      this.codeInput = newValue
    }
  },
  created(){
    this.codeInput = this.value
  }
}
</script>

<style scoped>

</style>